<template>
  <RouterView />
</template>

<script>
import { RouterView } from "vue-router";

export default {
  name: 'App',
  components: {
    RouterView
  }
}
</script>

<style>
@import "@/assets/css.css";
</style>
