import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "khalikes",
      component: () => import("../components/LandingPage.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("../components/ContactForm.vue"),
    },
    {
      path: "/order",
      name: "order",
      component: () => import("../components/OrderKhalikes.vue"),
    },
  ],
});

export default router;
